import React from "react";
import "./home.scss";
import Navbar from "../../section/navbar/Navbar.jsx";
import Hero from "../../section/hero/hero.jsx";
import About from "../../section/about/about.jsx";
import Devider from "../../section/devider/devider.jsx";
import Promo from "../../section/promo/promo.jsx";
import Produk from "../../section/produk/produk.jsx";
import Lokasi from "../../section/lokasi/lokasi.jsx";
import Footer from "../../section/footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";
import Newlaunch from "../../section/newlaunch/newlaunch.jsx";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Hero />
      <About />
      <Promo />
      <Newlaunch />
      <Produk />
      <Devider />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default home;
