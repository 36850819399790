import React from "react";
import "./lokasi.scss";
import gambarlokasi from "./maps-dekstop.webp";
import gambarmobile from "./maps-mobile.webp";

const lokasi = () => {
  return (
    <div id="lokasi" className="lokasi">
      <div className="container-lokasi">
        <div className="header-lokasi">
          <div className="judul-lokasi">Location 지역 위치</div>
          <div className="subjudul-lokasi">
            Hunian yang berada dilokasi yang strategis, dekat dengan berbagai
            pusat pendidikan, perbelanjaan, serta sarana transportasi umum.
          </div>
          <div className="list-lokasi">
            <div className="lokasi-point">📍10 Menit ke Akses Pintu Tol</div>
            <div className="lokasi-point">📍15 Menit ke Stasiun</div>
            <div className="lokasi-point">📍10 Menit ke Kota Bogor</div>
            <div className="lokasi-point">📍15 Menit ke IKEA & AEON Sentul</div>
          </div>
        </div>
        <div className="image-lokasi">
          <picture className="gambar">
            <source
              media="(max-width: 768px)"
              srcSet={gambarmobile}
              width="320"
            />
            <source
              media="(min-width: 768px)"
              srcSet={gambarlokasi}
              width="600"
            />
            <img className="gambar" src={gambarlokasi} alt="lokasi" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
