import A1lt1 from "./a1lt (1).webp";
import A1lt2 from "./a1lt (2).webp";
import A1lt3 from "./a1lt (3).webp";
import A1lt4 from "./a1lt (4).webp";
import A1lt5 from "./a1lt (5).webp";
import A1lt6 from "./a1lt (6).webp";
import A1lt7 from "./a1lt (7).webp";
import A1lt8 from "./a1lt (8).webp";
import A1lt9 from "./a1lt (9).webp";
import A1lt10 from "./a1lt (10).webp";
import A1lt11 from "./1s.webp";

const a1ltimages = [
  A1lt1,
  A1lt2,
  A1lt3,
  A1lt4,
  A1lt5,
  A1lt6,
  A1lt7,
  A1lt8,
  A1lt9,
  A1lt10,
  A1lt11,
];

export default a1ltimages;
