import Ka1001 from "./k100 (1).png";
import Ka1002 from "./k100 (2).png";
import Ka1003 from "./k100 (3).jpeg";
import Ka1004 from "./k100 (4).jpeg";
import Ka1005 from "./k100 (5).jpeg";
import Ka1006 from "./k100 (6).jpeg";
import Ka1007 from "./k100 (7).jpeg";
import Ka1008 from "./k100 (8).jpeg";
import Ka1009 from "./k100 (9).jpeg";
import Ka10010 from "./k100 (10).jpeg";
import Ka10011 from "./k100 (11).jpeg";
import Ka10012 from "./3s.webp";
import Ka10013 from "./3h.webp";

const k100 = [
  Ka1001,
  Ka1002,
  Ka1003,
  Ka1004,
  Ka1005,
  Ka1006,
  Ka1007,
  Ka1008,
  Ka1009,
  Ka10010,
  Ka10011,
  Ka10012,
  Ka10013,
];
export default k100;
