import React from "react";
import "./produk.scss";
import a1ltimages from "../../asset/lt1/a1lt";
import a2ltimages from "../../asset/lt2/a2lt";
import a3ltimages from "../../asset/k100/k100";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faHouse,
  faLocationDot,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const produk = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const courtyardwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(K-haus+40)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const verdantwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(K-Villa+70)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const theterracewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(K-Villa+100)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="produk" className="produk">
      <div className="container-produk">
        <div className="header-produk">
          <div className="judul-produk">OUR HOME 우리 집</div>
          <div className="subjudul-produk">KOREAN LIVING HOUSE IN BOGOR</div>
        </div>
        <div className="produk-card">
          <div className="kartu">
            <Slider {...settings}>
              {a1ltimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">K-Haus 40</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Bogor,
                  Jawa Barat
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">2.8</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 66m²</span>
              <FontAwesomeIcon icon={faHouse} />
              <span> : 40m²</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 2</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={courtyardwa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="kartu">
            <Slider {...settings}>
              {a2ltimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">K-Villa 70</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Bogor,
                  Jawa Barat
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">4.2</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 77m²</span>
              <FontAwesomeIcon icon={faHouse} />
              <span> : 70m²</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 3</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 2</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={verdantwa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="kartu">
            <Slider {...settings}>
              {a3ltimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Courtyard ${index + 1}`}
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">K-Villa 100</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Bogor,
                  Jawa Barat
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">5.9</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 77m²</span>
              <FontAwesomeIcon icon={faHouse} />
              <span> : 95m²</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 3</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 2</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={theterracewa} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default produk;
