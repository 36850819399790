import React from "react";
import "./newlaunch.scss";
import {
  faBed,
  faHouse,
  faLocationDot,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import k200 from "../../asset/k200/k200";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const newlaunch = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const k200wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(K-haus+200)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="newlaunch">
      <div className="container-newlaunch">
        <div className="header-produk">
          <div id="newlaunch" className="judul-produk">
            NEW LAUNCHING 새로운 출시
          </div>
          <div className="subjudul-produk">AREUM PARC BOGOR</div>
        </div>
      </div>
      <div className="newlaunch-card">
        <div className="kartu">
          <Slider {...settings}>
            {k200.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt={`Courtyard ${index + 1}`}
              />
            ))}
          </Slider>
          <div className="container-title-card">
            <div className="titlecard">
              <div className="judul-rumah1">Areum Parc</div>
              <div className="sub-judul-rumah">
                <FontAwesomeIcon color="red" icon={faLocationDot} /> Bogor, Jawa
                Barat
              </div>
              <div className="cicilan">
                Design by Hadi Prana
                {/* <div className="start">Start From</div>
                <div className="det">
                  <div className="angka">1.6</div>
                  <div className="ket">Miliar</div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon icon={faMaximize} />
            <span> : 105m²</span>
            <FontAwesomeIcon icon={faHouse} />
            <span> : 170m²</span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 4</span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 5</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={k200wa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newlaunch;
