import React from "react";
import "./fasilitas.scss";
import { BiCycling } from "react-icons/bi";
import { FaRunning } from "react-icons/fa";
import { GiOpenGate, GiWaterFountain } from "react-icons/gi";
import { MdSportsTennis } from "react-icons/md";
import { PiPark } from "react-icons/pi";

const fasilitas = () => {
  return (
    <div className="fasilitas">
      <div className="container-fasilitas">
        <div className="judul-fasilitas">
          Healthy lifestyle, in serene facilities
        </div>
        <div className="container-list-fasilitas">
          <div className="list-fasilitas">
            <div className="list">
              <span>
                <PiPark />
              </span>
              &nbsp;&nbsp; Private Club House
            </div>
            <div className="list">
              <span>
                <MdSportsTennis />
              </span>
              &nbsp;&nbsp; Lapangan Tenis
            </div>
            <div className="list">
              <span>
                <GiOpenGate />
              </span>
              &nbsp;&nbsp; Triple Gate System
            </div>
          </div>
          <div className="list-fasilitas">
            <div className="list">
              <span>
                <FaRunning />
              </span>
              &nbsp;&nbsp; Jogging Track
            </div>
            <div className="list">
              <span>
                <BiCycling />
              </span>
              &nbsp;&nbsp; Bicycle Path
            </div>
            <div className="list">
              <span>
                <GiWaterFountain />
              </span>
              &nbsp;&nbsp; Water Playground
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default fasilitas;
