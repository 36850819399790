import React from "react";
import "./devider.scss";
import fasimages from "../../asset/fas/fas.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const devider = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1,
          arrows: false, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="devider">
      <div className="container-devider">
        <Slider {...settings}>
          {fasimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt="fasilitas"
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default devider;
