import A2lt1 from "./a2lt (1).webp";
import A2lt2 from "./a2lt (2).webp";
import A2lt3 from "./a2lt (3).webp";
import A2lt4 from "./a2lt (4).webp";
import A2lt5 from "./a2lt (5).webp";
import A2lt6 from "./a2lt (6).webp";
import A2lt7 from "./a2lt (7).webp";
import A2lt8 from "./a2lt (8).webp";
import A2lt9 from "./a2lt (9).webp";
import A2lt10 from "./a2lt (10).webp";
import A2lt11 from "./a2lt (11).webp";
import A2lt12 from "./a2lt (12).webp";
import A2lt13 from "./2s.webp";
import A2lt14 from "./2h.webp";

const a2ltimages = [
  A2lt1,
  A2lt2,
  A2lt3,
  A2lt4,
  A2lt5,
  A2lt6,
  A2lt7,
  A2lt8,
  A2lt9,
  A2lt10,
  A2lt11,
  A2lt12,
  A2lt13,
  A2lt14,
];

export default a2ltimages;
