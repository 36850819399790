import React from "react";
import "./about.scss";
import { PiHouseLine } from "react-icons/pi";
import { BsCreditCard } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Fasilitas from "../../section/fasilitas/fasilitas.jsx";
import background from "../../asset/areum-background.png";

const about = () => {
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(about)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(brosur)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about">Areum Parc 아름 </div>
          <div className="subjudul-about">Bogor, Jawa Barat</div>
          <div className="sec-about">
            <div className="home-about">
              <div className="icon-home-about">
                <PiHouseLine />
              </div>
              <div className="ket-home">
                <div className="judul-home-about">Tipe Propety</div>
                <div className="subjudul-home-about">
                  Rumah Ready Dan Indent
                </div>
              </div>
              <div className="button-home-about">
                <button className="brosur" onClick={wabrosur}>
                  Brosur
                </button>
              </div>
            </div>
            <div className="pembayaran-about">
              <div className="icon-pembayaran-about">
                <BsCreditCard />
              </div>
              <div className="ket-pembayaran">
                <div className="judul-pembayaran-about">Jenis Pembayaran</div>
                <div className="subjudul-pembayaran-about">
                  Cash dan KPR 100% Approved
                </div>
              </div>
              <div className="button-pembayaran-about">
                <button className="card" onClick={waabout}>
                  <span className="waicon">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                  &nbsp; Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="about-us">
            <div className="judul-about-us">
              Annyeong Bogor! Areum Parc Imnida
            </div>
            <div className="subjudul-about-us">
              Areum Parc Bogor, proyek hunian terbaru dari MAS Group, adalah
              konsep Korean Village pertama di Bogor yang menggabungkan
              keindahan arsitektur Korea dengan nuansa yang indah, nyaman, dan
              terjangkau. Areum Parc Bogor memiliki berbagai fasilitas seperti
              Cluster Satu Pintu yang meningkatkan keamanan dan kenyamanan,
              serta tempat bermain anak-anak.
            </div>
          </div>
        </div>
        <Fasilitas />
      </div>
      <div className="floating-image">
        <img src={background} alt="" />
      </div>
    </div>
  );
};

export default about;
